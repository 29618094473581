import {
  EmakiConsumableBaseDataType,
  EmakiConsumableType,
  EmakiLinkableItemType,
  EmakiPhysicalVoucherType,
} from './types';
import { getEmakiConsumableImages, getShopifyHandle } from './utils';

const EMAKI_TOKEN: EmakiConsumableType = {
  id: '100',
  name: 'Emaki Season 2',
  type: 'emakiToken',
  ...getEmakiConsumableImages('Emaki Season 2'),
};

const EMAKI_LINKABLE_ITEMS: EmakiLinkableItemType[] = (
  [
    { id: '101', name: '!OOH', slot: 'Accessory' },
    { id: '102', name: 'Snake Tattoo', slot: 'Accessory' },
    { id: '103', name: 'Banana', slot: 'Mouth' },
    { id: '104', name: 'Maple Leaf', slot: 'Mouth' },
    { id: '105', name: 'Buru', slot: 'Kami' },
    { id: '106', name: 'Bone Wings', slot: 'Accessory' },
    { id: '107', name: 'Bone Armor', slot: 'Clothing' },
    { id: '108', name: 'Scannerz', slot: 'Eyes' },
    { id: '110', name: 'Roya Nao Leaves', slot: 'Special Effect' },
    { id: '111', name: 'Fireflies', slot: 'Special Effect' },
    { id: '112', name: 'Sandstorm', slot: 'Special Effect' },
    { id: '113', name: 'Blizzard', slot: 'Special Effect' },
    { id: '114', name: 'Nagareru Mizu', slot: 'Special Effect' },
    { id: '115', name: 'Downfall', slot: 'Special Effect' },
    { id: '116', name: 'Central District', slot: 'Background' },
    { id: '117', name: 'Jumebara Swamp', slot: 'Background' },
    { id: '118', name: 'Dunes of Iyakoy', slot: 'Background' },
    { id: '119', name: 'Alzi Peak', slot: 'Background' },
    { id: '120', name: 'Vodione Coast', slot: 'Background' },
    { id: '121', name: 'Urban Core', slot: 'Background' },
    { id: '122', name: 'Santa Hat', slot: 'Hat' },
    { id: '123', name: 'Winterveil', slot: 'Background' },
  ] as (EmakiConsumableBaseDataType & Pick<EmakiLinkableItemType, 'slot'>)[]
).map((item) => ({
  ...item,
  type: 'linkableItem',
  ...getEmakiConsumableImages(item.name),
  shopifyHandle: getShopifyHandle(item.name),
}));

const EMAKI_PHYSICAL_VOUCHER_ITEMS: EmakiPhysicalVoucherType[] = (
  [
    {
      id: '109',
      name: 'CyberKongz nanoblock Myoo',
    },
    {
      id: '124',
      name: 'Genkai Warrior Hoodie - Powered by 9dcc',
      nameShort: 'Genkai Warrior Hoodie',
    },
    {
      id: '125',
      name: 'CyberKongz Black Vest',
    },
  ] as EmakiConsumableBaseDataType[]
).map((item) => ({
  ...item,
  type: 'physicalVoucher',
  ...getEmakiConsumableImages(item.name, { isExpired: true }),
  shopifyHandle: getShopifyHandle(item.name),
}));

export const EMAKI_CONSUMABLES_SEASON_2: EmakiConsumableType[] = [
  EMAKI_TOKEN,
  ...EMAKI_LINKABLE_ITEMS,
  ...EMAKI_PHYSICAL_VOUCHER_ITEMS,
];
